import { ApiBaseService } from 'api/apibase.service';
import { AxiosPromise } from 'axios';
import { OrderConfirmation } from 'redux/order-confirmation/types/types';

class OrderConfirmationApiService extends ApiBaseService {
  find(saleId: number): AxiosPromise<OrderConfirmation> {
    return this.apibase.get<OrderConfirmation>(
      `/order-confirmation/sale-details/${saleId}`,
    );
  }

  removeCountryCode(phoneNumber: string): string {
    if (phoneNumber.startsWith('+1')) {
      return phoneNumber.slice(2);
    }
    return phoneNumber;
  }

  checkPhoneNumberExists(phoneNumber: string): AxiosPromise<number> {
    const cleanNumber = this.removeCountryCode(phoneNumber);
    return this.apibase.get<number>(
      `/orders/check-phone-number-exists/${cleanNumber}`,
    );
  }
}
const orderConfirmationApiService = new OrderConfirmationApiService();

export { orderConfirmationApiService };
