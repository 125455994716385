import {
  UserAccountInformation,
  UserSession,
} from 'providers/Session/feature/user.types';

interface Traits {
  email: string;
  phone: string;
  birthday: string | Date;
  firstName: string;
  lastName: string;
  address: {
    city: string;
    state: string;
  };
}

export const buildTraits = (
  user?: UserSession,
  accountInfo?: UserAccountInformation,
): Traits => ({
  email: user?.userEmail || '',
  phone:
    accountInfo?.accountInfo[0]?.cellPhone ||
    accountInfo?.accountInfo[0]?.workPhone ||
    accountInfo?.accountInfo[0]?.homePhone ||
    '',
  birthday: accountInfo?.accountInfo[0]?.birthday || '',
  firstName: user?.userName?.split(' ')[0] || '',
  lastName: user?.userName?.split(' ')[1] || '',
  address: {
    city: accountInfo?.accountInfo[0]?.city || '',
    state: accountInfo?.accountInfo[0]?.stateAbbreviation || '',
  },
});

export const buildUserTraits = (
  userEmail: string,
  user?: UserSession,
  accountInformation?: UserAccountInformation,
): Traits => ({
  email: userEmail || user?.userEmail || '',
  phone:
    accountInformation?.accountInfo[0].cellPhone ||
    accountInformation?.accountInfo[0].workPhone ||
    accountInformation?.accountInfo[0].homePhone ||
    '',
  birthday: accountInformation?.accountInfo[0].birthday || '',
  firstName: (user && user.userName.split(' ')[0]) || '',
  lastName: (user && user.userName.split(' ')[1]) || '',
  address: {
    city: accountInformation?.accountInfo[0].city || '',
    state: accountInformation?.accountInfo[0].stateAbbreviation || '',
  },
});
