import 'es6-shim';
import 'styles/App.css';
import 'translations/i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import { persistStore } from 'redux-persist';
import { plugin } from 'utils/telemetry/app-insights';
import { root } from 'utils/root';
import { store } from 'redux/store';
import Box from '@mui/material/Box';
import React from 'react';
import ReactDOM from 'react-dom';

const persistor = persistStore(store);

const AppContainer = React.lazy(
  () => import('containers/AppContainer/AppContainer'),
);

ReactDOM.render(
  <Provider store={store}>
    <AppInsightsContext.Provider value={plugin}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <HelmetProvider>
                <React.Suspense fallback={<Box minHeight="100vh" />}>
                  <AppContainer />
                </React.Suspense>
              </HelmetProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </React.StrictMode>
      </PersistGate>
    </AppInsightsContext.Provider>
  </Provider>,
  root,
);
