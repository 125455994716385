import { Environment, getCurrentEnvironment } from 'utils/environment';
import { HomePageContent } from 'components/Home/types';

export const getFallbackResponse = async (): Promise<HomePageContent> => {
  const environment = getCurrentEnvironment();
  switch (environment) {
    case Environment.LOCAL:
      return (
        await import('components/Home/feature/fallback/fallback-response-local')
      ).fallbackResponseLocal;
    case Environment.DEVELOPMENT:
      return (
        await import('components/Home/feature/fallback/fallback-response-dev')
      ).fallbackResponseDev;
    case Environment.QA:
      return (
        await import('components/Home/feature/fallback/fallback-response-qa')
      ).fallbackResponseQa;
    case Environment.PRODUCTION:
      return (
        await import('components/Home/feature/fallback/fallback-response-prod')
      ).fallbackResponseProd;
    default:
      throw new Error('Unknown environment');
  }
};
